









import { defineComponent, SetupContext } from '@vue/composition-api'

import { NavTabsItemProps } from './NavTabsItem.contracts'
import { navTabsItemProps, useNavTabsItem } from './NavTabsItem.hooks'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const NavTabsItem = defineComponent({
  name: 'NavTabsItem',
  props: navTabsItemProps,
  emits: ['click'],

  setup (props: NavTabsItemProps, { emit }: SetupContext) {
    return useNavTabsItem(props, emit)
  }
})

export default NavTabsItem
