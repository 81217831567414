// Copyright © 2022 Move Closer

import { canBeDisabledProp } from '../../../composables'
import { ComponentObjectPropsOptions, Emit } from '../../../vue-api'

import { NavTabsItemProps } from './NavTabsItem.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const navTabsItemProps: ComponentObjectPropsOptions<NavTabsItemProps> = {
  ...canBeDisabledProp,

  /**
   * Determines whether the tab is the currently active one.
   */
  active: {
    type: Boolean,
    required: false,
    default: true
  },

  anchor: {
    type: [String],
    required: false,
    default: ''
  },

  /**
   * Tab's label.
   */
  label: {
    type: [String, Function],
    required: true
  },

  /**
   * Index of tab.
   */
  id: {
    type: [String],
    required: true
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const useNavTabsItem = (props: NavTabsItemProps, emit: Emit) => {
  /**
   * Handles the @click event on the root `<li>` element.
   */
  const onClick = (): void => {
    if (!props.isDisabled) {
      emit('click')
    }
  }

  return { onClick }
}
