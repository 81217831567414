// Copyright © 2021 Move Closer

import { computed, PropType } from '@vue/composition-api'

import { ComponentObjectPropsOptions, Emit } from '../../vue-api'
import { canBeDisabledProp } from '../../composables'

import { NavTabsProps } from './NavTabs.contracts'
import { NavTabsItemProps } from './_partials'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl> (original)
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 */
export const navTabsProps: ComponentObjectPropsOptions<NavTabsProps> = {
  ...canBeDisabledProp,

  /**
   * The ID of the currently-active tab.
   */
  active: {
    type: String,
    required: true
  },

  /**
   * List of tabs to render.
   */
  items: {
    type: Array as PropType<NavTabsItemProps[]>,
    required: true
  },

  /**
   * Determine if should use justified tabs.
   */
  justify: {
    type: Boolean,
    required: false,
    default: false
  },

  isWrapped: {
    type: Boolean,
    required: false,
    default: false
  }
}

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const useNavTabs = (props: NavTabsProps, emit: Emit) => {
  /**
   * Currently-active tab.
   */
  const activeTab = computed<string>({
    get () {
      return props.active
    },
    set (value): void {
      emit('update:active', value)
    }
  })

  /**
   * Activates the tab of a passed-in ID.
   * @param id - The ID of the tab that is to be set as 'active'.
   */
  const activateTab = (id: string): void => {
    if (!props.isDisabled) {
      activeTab.value = id
    }
  }

  /**
   * Checks if the tab of a passed-in ID is the currently-active one.
   * @param id - The ID of the tab that is to be checked.
   */
  const isTabActive = (id: string): boolean => {
    return activeTab.value === id
  }

  return { activeTab, activateTab, isTabActive }
}
