












import { defineComponent, SetupContext } from '@vue/composition-api'

import { NavTabsItem } from './_partials'
import { NavTabsProps } from './NavTabs.contracts'
import { navTabsProps, useNavTabs } from './NavTabs.hooks'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (edited)
 */

export const NavTabs = defineComponent({
  name: 'NavTabs',
  components: { NavTabsItem },
  props: navTabsProps,
  emits: ['update:activeItem'],
  setup (props: NavTabsProps, { emit }: SetupContext) {
    return useNavTabs(props, emit)
  }

})

export default NavTabs
